<template>
  <div class="relative mt-2-5">
    <Identity class="icon-background"/>
  </div>
</template>

<script>
import Identity from '@/assets/icons/identity.svg';

export default {
  name: 'IdentityBackground',
  components: {
    Identity,
  },
};
</script>
