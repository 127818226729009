<template>
  <div>
    <form class="mt-7-5 leading-none">
      <div class="flex items-center justify-between">
        <div class="font-bold text-xl24 md:text-xl20">
          {{ $t('identity.personal_details') }}
        </div>
      </div>

      <div v-if="isKycComplete" class="flex flex-col mt-7-5">
        <div class="font-semibold text-n-lg text-text-body mb-4-1">
          {{ $t('identity.full_name') }}
        </div>
        <input
          disabled
          v-model="form.full_name"
          type="text"
          class="v4 cursor-not-allowed"
          data-testid="full_name"
        />
      </div>

      <div class="flex flex-col mt-7-5">
        <div class="font-semibold text-n-lg text-text-body mb-4-1">
          {{ $t('email.address') }}
        </div>
        <input
          disabled
          v-model="form.email"
          type="email"
          class="v4 cursor-not-allowed"
          data-testid="email"
        />
      </div>

      <div v-if="isKycComplete" class="flex flex-col mt-7-5">
        <div class="font-semibold text-n-lg text-text-body mb-4-1">
          {{ $t('address.street') }}
        </div>
        <input
          disabled
          v-model="form.address_street"
          type="text"
          class="v4 cursor-not-allowed"
          data-testid="address-street"
        />
      </div>

      <div v-if="isKycComplete" class="flex mt-7-5">
        <div class="w-full">
          <div class="font-semibold text-n-lg text-text-body mb-4-1">
            {{ $t('address.city') }}
          </div>
          <input
            disabled
            v-model="form.address_city"
            type="text"
            class="v4 cursor-not-allowed"
            data-testid="address-city"
          />
        </div>
        <div class="w-full ml-4-5">
          <div class="font-semibold text-n-lg text-text-body mb-4-1">
            {{ $t('address.state') }}
          </div>
          <input
            disabled
            v-model="form.address_state"
            type="text"
            class="v4 cursor-not-allowed"
            data-testid="address-state"
          />
        </div>
      </div>

      <div v-if="isKycComplete" class="flex mt-7-5">
        <div class="w-full">
          <div class="font-semibold text-n-lg text-text-body mb-4-1">
            {{ $t('address.zip') }}
          </div>
          <input
            disabled
            v-model="form.address_zip"
            type="text"
            class="v4 cursor-not-allowed"
            data-testid="address-zip"
          />
        </div>
        <div class="w-full ml-4-5">
          <div class="font-semibold text-n-lg text-text-body mb-4-1">
            {{ $t('address.country') }}
          </div>
          <input
            disabled
            v-model="form.address_country"
            type="text"
            class="v4 cursor-not-allowed"
            data-testid="address_country"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'IdentityForm',

  data: function () {
    return {
      apiObjectName: 'current_user',
      form: {
        email: '',
        full_name: '',
        address_street: '',
        address_city: '',
        address_state: '',
        address_zip: '',
        address_country: '',
      },
    };
  },

  async mounted() {
    this.loadData();
    await this.updateKycComplete();
  },

  computed: {
    ...mapGetters('user', ['isKycComplete', 'getUserFullName']),
    ...mapState('user', ['user_info']),
  },

  methods: {
    ...mapActions('user', ['updateKycComplete']),

    async loadData() {
      this.form = {
        ...this.user_info,
        full_name: this.getUserFullName,
      };
    },
  },
};
</script>

<style scoped></style>
