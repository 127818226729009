<template>
  <LayoutMainPages>
    <div class="flex">
      <!-- Left side -->
      <div :class="`${!isMobileDevice ? 'left-side' : ''} mr-14 w-full xl:mr-0`">
        <Title :title="$t('identity.title')" />
        <IdentityForm />
      </div>
      <IdentityBackground class="xl:hidden" />
    </div>
  </LayoutMainPages>
</template>

<script>
import { LayoutMainPages, Title } from '@/components/misc';
import IdentityForm from '@/components/identity/IdentityForm';
import IdentityBackground from '@/components/identity/IdentityBackground';

export default {
  name: 'Identity',
  components: {
    LayoutMainPages,
    Title,
    IdentityForm,
    IdentityBackground,
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'IDENTITY_VIEWED',
    });
  },
};
</script>

<style scoped>
.left-side {
  max-width: 606px;
}
</style>
